import React, { useCallback, useEffect, useState } from 'react'
import styles from './index.module.scss'
import { EmailList } from './EmailList'
import { EMAIL_REGEX } from '~constants'
import { IEmailItem, IRecipientsModalBody } from '~interfaces'
import { RecipientsModalControlPanel } from './RecipientsModalControlPanel'
import { UserAccessList } from './UserAccessList'
import { GetUserAccessList } from '~services'
import { STATUS_CODES } from '~enums'

export const RecipientsModalBody = ({ fileName, videoId, shareUrl, handleShareResponse }: IRecipientsModalBody) => {
  const [emails, setEmails] = useState<IEmailItem[]>([])
  const [selectedEmail, setSelectedEmail] = useState<IEmailItem>()
  const [selectedEmailIdx, setSelectedEmailIdx] = useState<number>()
  const [numValidEmails, setNumValidEmails] = useState<number>(0)
  const [notifyPeople, setNotifyPeople] = useState<boolean>(false)
  const [message, setMessage] = useState('')
  const [accessList, setAccessList] = useState<string[]>([])
  const maxChars = 150

  const regexTester = new RegExp(EMAIL_REGEX)

  useEffect(() => {
    GetUserAccessList({ videoId: videoId.toString() })
      .then(res => {
        if (res.status === STATUS_CODES.NO_USERS_FOUND) {
          setAccessList([])
        }
        if (res.status === STATUS_CODES.SUCCESSFUL_USERS_ACCESS_LIST) {
          setAccessList(res.emails)
        }
      })
      .catch(err => console.error(err))
  }, [])

  const addNewEmail = (email: string, isValid: boolean) => {
    const newEmail: IEmailItem = { email, isValid }
    setEmails([...emails, newEmail])
    if (isValid) {
      const newNum = numValidEmails + 1
      setNumValidEmails(newNum)
    }
  }

  const validateEmail = (email: string) => {
    return regexTester.test(email) && email.length < 255
  }

  const removeEmailByIdx = (deletedIndex: number) => {
    const deletedEmail: IEmailItem = emails[deletedIndex]
    console.log(`Deleting entry: ${deletedEmail.email}`)
    const newEmails = emails.filter((_, idx) => idx !== deletedIndex)
    setEmails(newEmails)
    if (deletedEmail.isValid) {
      const newNum = numValidEmails - 1
      setNumValidEmails(newNum)
    }
  }

  const handleKeyUp = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      const { key } = event
      const editableDiv = document.getElementById('text-input')
      //When there are added emails but no new text,
      if (!editableDiv?.textContent && emails.length) {
        //And a user hits 'Backspace', the last email will be selected
        if (key === 'Backspace') {
          const idx = emails.length - 1
          if (selectedEmail?.email !== emails[emails.length - 1].email && selectedEmailIdx !== idx) {
            const selEmail = emails[idx]
            setSelectedEmailIdx(idx)
            setSelectedEmail(selEmail)
            return
            //If the user hits 'Backspace' again, the last email will be deleted
          } else if (selectedEmail?.email === emails[emails.length - 1].email && selectedEmailIdx === idx) {
            removeEmailByIdx(idx)
            setSelectedEmail(undefined)
            setSelectedEmailIdx(undefined)
            return
          }
        }
      }
      //When there is new text and less than 50 added emails
      if (editableDiv && emails.length < 50) {
        if ((key === ' ' || key === 'Spacebar' || key === ',' || key === ';' || key === 'Enter') && editableDiv.textContent?.length) {
          const newEmail = editableDiv.textContent
          const isValid = validateEmail(newEmail)
          addNewEmail(newEmail, isValid)
          editableDiv.textContent = ''
        }
        //When there is new text and 50 or more added emails
      } else if (editableDiv && emails.length >= 50) {
        console.log('50 is the max!')
      }
    },
    [emails, selectedEmail]
  )

  const handleKeyDown = useCallback((event: React.KeyboardEvent<HTMLDivElement>) => {
    const { key } = event
    //We prevent these keys from adding unwanted characters to the text
    const SPECIAL_KEYS = [' ', 'Spacebar', ',', ';', 'Enter']
    if (SPECIAL_KEYS.includes(key)) {
      event.preventDefault()
    }
  }, [])

  const handleMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { target } = e
    const newMessage = (target as HTMLTextAreaElement).value
    // Only allow typing if the length is within the limit
    if (newMessage.length <= maxChars) {
      setMessage(newMessage) // Keeps content at 150 characters without adding more
    }
  }

  const isOverLimit = message.length >= maxChars

  return (
    <div className={styles['modal-body-container']}>
      <div id="email-input" className={styles['email-input']}>
        <EmailList
          emails={emails}
          removeEmail={removeEmailByIdx}
          selectedEmail={selectedEmail}
          setSelectedEmail={setSelectedEmail}
          selectedEmailIdx={selectedEmailIdx}
          setSelectedEmailIdx={setSelectedEmailIdx}
        />
        <div
          id="text-input"
          data-testid="text-input"
          className={styles['text-input']}
          contentEditable={'true'}
          data-placeholder={emails.length ? '' : 'External emails...'}
          onKeyDown={handleKeyDown}
          onKeyUp={handleKeyUp}
        />
      </div>
      {notifyPeople && numValidEmails > 0 && (
        <div>
          <textarea
            id="message-input"
            data-testid="message-input"
            value={message}
            onChange={handleMessageChange}
            className={`${styles['message-div']} ${isOverLimit ? styles['error'] : ''}`}
            placeholder={message.length ? '' : 'Message (Optional)'}
          />
          {isOverLimit && <p className={styles['character-limit-warning']}>Limit to 150 characters</p>}
        </div>
      )}
      {numValidEmails > 0 && (
        <div className={styles['notify-details-container']}>
          <div className={styles['notify-left']}>
            <div className={styles['notify-checkbox']}>
              <input type="checkbox" checked={notifyPeople} onChange={() => setNotifyPeople(!notifyPeople)} />
              <label className={styles['notify-label']}>Notify people</label>
            </div>
          </div>
          <div className={styles['notify-right']}>
            <div className={styles['num-share-status']}>Share with {numValidEmails} people</div>
          </div>
        </div>
      )}
      {accessList.length ? <UserAccessList accessList={accessList} /> : null}
      <RecipientsModalControlPanel
        emails={emails}
        message={message}
        fileName={fileName}
        videoId={videoId}
        shareUrl={shareUrl}
        enableShareButton={numValidEmails > 0}
        handleShareResponse={handleShareResponse}
      />
    </div>
  )
}
