import { ShareIcon } from '~elements'

interface ShareButtonProps {
  displayText: string
  //eslint-disable-next-line
  handleClick: Function
  styles: {
    readonly [key: string]: string
  }
  displayIcon?: boolean
  disabled?: boolean
}

export const SimpleShareButton = ({ styles, handleClick, displayText, displayIcon = true, disabled = false }: ShareButtonProps) => {
  return (
    <button
      onClick={() => {
        handleClick()
      }}
      className={styles['share-button']}
      data-testid={'share-button'}
      disabled={disabled}
    >
      {displayIcon && <ShareIcon />}
      <div className={styles['item-display-name']}>{displayText}</div>
    </button>
  )
}
