import { PASSWORD_REGEX_EXPRESSION } from '~constants'
import { FORGOT_FIELD_KEYS, FORGOT_PASSWORD_BTN_COLOR } from '~enums'
import { IFormBlock } from '~interfaces'

export const ForgotPasswordForm: IFormBlock = {
  componentName: 'LoginForm',
  id: '1',
  fields: [
    {
      type: 'text',
      id: 'verificationCode',
      hidden: true,
      placeholder: 'Verification Code',
      label: 'Verification Code',
      required: true,
      // Assumption this will always be a number, but could be updated to have alpha-numeric characters.
      validation: {
        message: 'This code is invalid!',
        regex: '^[0-9]{6}$'
      }
    },
    {
      type: 'password',
      id: FORGOT_FIELD_KEYS.NEW_PASSWORD,
      hidden: true,
      placeholder: 'New Password',
      label: 'New Password',
      required: true,
      // Requires an uppercase, lowercase, special character and to be at least 8 characters long. It is pulled from OWASP and what cognito enforces.
      validation: {
        message: 'This field is required.',
        regex: PASSWORD_REGEX_EXPRESSION
      }
    },
    {
      type: 'password',
      id: FORGOT_FIELD_KEYS.CONFIRM_PASSWORD,
      hidden: true,
      placeholder: 'Confirm Password',
      label: 'Confirm Password',
      required: true,
      // Requires an uppercase, lowercase, special character and to be at least 8 characters long. It is pulled from OWASP and what cognito enforces.
      validation: {
        message: "Password does't match!",
        regex: null
      }
    },
    {
      type: 'email',
      id: 'email',
      hidden: false,
      placeholder: 'Email *',
      label: 'Email *',
      required: true,
      validation: {
        message: 'This email is invalid.',
        regex: '^[a-zA-Z0-9_+&*-]+(?:\\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,}$'
      }
    }
  ],
  formType: 'forgot',
  heading: {
    level: 1,
    title: 'Forgot your password?'
  },
  rememberMe: null,
  submitButton: {
    text: 'Submit',
    color: FORGOT_PASSWORD_BTN_COLOR.WHITE
  },
  backToSignIn: {
    signIn: {
      link: '/login',
      text: 'Or sign In'
    }
  },
  paragraph: {
    fontSize: '16px',
    justify: 'left',
    text: { html: 'Please put in your email to reset your password.' }
  }
}
