import { IToDThumbnailDetails } from '~interfaces'
import { LOCAL_STORAGE } from '~enums'
import { getCookie, getTimesOfDayImageUrls } from '~utils'

interface ITimeOfDayData {
  data: Array<IToDThumbnailDetails>
}

export const GetTimesOfDay = async ({ isRecipient, videoId }: { isRecipient?: boolean; videoId?: string }): Promise<ITimeOfDayData> => {
  try {
    const headers = new Headers()
    const userSessionData = localStorage.getItem(LOCAL_STORAGE.USER_KEY)

    if (!userSessionData && !isRecipient) {
      throw new Error('Session data is missing.')
    }

    if (userSessionData) {
      const { id } = JSON.parse(userSessionData)

      headers.append('Authorization', `Bearer ${id}`)
    } else if (isRecipient) {
      const recipientCookie = getCookie('epu')
      headers.append('Authorization', `Bearer ${recipientCookie}`)
    }

    const result = await fetch(`${process.env.NEXT_PUBLIC_API_ENDPOINT}/times-of-day${isRecipient ? `?id=${videoId}` : ''}`, {
      method: 'GET',
      headers
    })
    const timeOfDayData = await result.json()
    const timeOfDayDataWithImageUrls = getTimesOfDayImageUrls(timeOfDayData)
    return { data: timeOfDayDataWithImageUrls }
  } catch (e) {
    throw new Error('Unable to get time of day data.')
  }
}
