import { usePreviewStore } from 'store'
import { useState } from 'react'
import styles from 'components/Header/index.module.scss'
import { SimpleShareButton, ManageRecipientsModal } from '~elements'

export const NavShareVideo = () => {
  const { selectedVideo } = usePreviewStore()
  const [manageRecipientsModalOpen, setManageRecipientsModalOpen] = useState<boolean>(false)

  const handleClick: React.MouseEventHandler = () => {
    setManageRecipientsModalOpen(true)
  }

  return (
    selectedVideo && (
      <>
        <SimpleShareButton displayText={'share'} handleClick={handleClick} styles={styles} />
        <ManageRecipientsModal
          fileName={selectedVideo.title}
          videoId={Number(selectedVideo.id)}
          isOpen={manageRecipientsModalOpen}
          handleModalClose={() => setManageRecipientsModalOpen(false)}
        />
      </>
    )
  )
}
