export const WarningIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M2.08057 20.5248L11.9998 3.4248L21.9191 20.5248H2.08057ZM3.98634 19.4306H20.0133L11.9998 5.61326L3.98634 19.4306ZM12.0639 17.9767C12.2456 17.9767 12.3998 17.9118 12.5267 17.7821C12.6537 17.6523 12.7171 17.4991 12.7171 17.3225C12.7171 17.1458 12.6521 16.9899 12.522 16.8546C12.3919 16.7194 12.2361 16.6517 12.0545 16.6517C11.8729 16.6517 11.7171 16.7191 11.5871 16.8537C11.4571 16.9884 11.3921 17.1476 11.3921 17.3315C11.3921 17.5082 11.4587 17.6599 11.5918 17.7867C11.725 17.9134 11.8823 17.9767 12.0639 17.9767ZM11.5075 15.5806H12.6017V10.2383H11.5075V15.5806Z"
        fill="#F52500"
      />
    </svg>
  )
}
