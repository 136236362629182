export const UserIcon = () => {
  return (
      <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="0.5" y="0.5" width="29" height="29" rx="14.5" stroke="#757575" />
          <mask id="mask0_7794_5833" maskUnits="userSpaceOnUse" x="7" y="7" width="24" height="24">
              <rect x="7" y="7" width="24" height="24" fill="white" />
          </mask>
          <g mask="url(#mask0_7794_5833)">
              <path d="M14.9999 14.51C14.3486 14.51 13.8012 14.2882 13.3576 13.8447C12.9141 13.4011 12.6923 12.852 12.6923 12.1972C12.6923 11.5425 12.9141 10.9951 13.3576 10.5549C13.8012 10.1147 14.3486 9.89453 14.9999 9.89453C15.6513 9.89453 16.1987 10.1147 16.6423 10.5549C17.0858 10.9951 17.3076 11.5425 17.3076 12.1972C17.3076 12.852 17.0858 13.4011 16.6423 13.8447C16.1987 14.2882 15.6513 14.51 14.9999 14.51ZM9.47363 20.2216V19.0272C9.47363 18.6865 9.57172 18.3774 9.76791 18.0999C9.96409 17.8224 10.2236 17.6062 10.5465 17.4514C11.3151 17.1028 12.068 16.8397 12.8052 16.6621C13.5426 16.4845 14.274 16.3956 14.9996 16.3956C15.7252 16.3956 16.4563 16.485 17.1927 16.6637C17.9293 16.8422 18.68 17.1063 19.4449 17.4559C19.775 17.6091 20.0378 17.8241 20.2332 18.1009C20.4286 18.3778 20.5263 18.6865 20.5263 19.0272V20.2216H9.47363ZM10.1417 19.5535H19.8582V19.0272C19.8582 18.8352 19.7944 18.6517 19.6667 18.4767C19.539 18.3016 19.3636 18.1555 19.1407 18.0383C18.4503 17.702 17.7638 17.4555 17.0812 17.2988C16.3984 17.1421 15.7047 17.0637 14.9999 17.0637C14.2952 17.0637 13.5988 17.1421 12.9106 17.2988C12.2226 17.4555 11.5378 17.702 10.8562 18.0383C10.6332 18.1555 10.4583 18.3016 10.3316 18.4767C10.205 18.6517 10.1417 18.8352 10.1417 19.0272V19.5535ZM14.9999 13.8419C15.4598 13.8419 15.848 13.6836 16.1646 13.367C16.4813 13.0505 16.6397 12.6622 16.6397 12.2024C16.6397 11.7425 16.4813 11.3542 16.1646 11.0375C15.848 10.7209 15.4598 10.5626 14.9999 10.5626C14.5401 10.5626 14.1519 10.7209 13.8353 11.0375C13.5186 11.3542 13.3602 11.7425 13.3602 12.2024C13.3602 12.6622 13.5186 13.0505 13.8353 13.367C14.1519 13.6836 14.5401 13.8419 14.9999 13.8419Z" fill="white" />
          </g>
      </svg>
  )
}