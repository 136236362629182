export enum EXOSPHERE_THUMB_ICON {
  NORTH_EAST = 'north-east',
  NORTH = 'north',
  NORTH_WEST = 'north-west',
  WEST = 'west',
  TOP = 'top',
  EAST = 'east',
  SOUTH_EAST = 'south-east',
  SOUTH = 'south',
  SOUTH_WEST = 'south-west'
}

export enum FORGOT_FIELD_KEYS {
  CONFIRM_PASSWORD = 'confirmPassword',
  NEW_PASSWORD = 'newPassword'
}

export enum FORGOT_PASSWORD_ACTIONS {
  INIT = 'init',
  COGNITO_FAILURE = 'cognito_failure',
  CONFIRM = 'confirm',
  RESEND_CODE = 'resend-verification-code'
}

export enum FORGOT_PASSWORD_BTN_COLOR {
  WHITE = 'white',
  TURQUOISE = 'turquoise'
}

export enum LOCAL_STORAGE {
  EXO_REMEMBER_ME = 'exo-remember-me',
  USER_KEY = 'x-amz-user-token',
  EXO_PREVIEW = 'exo-preview-url',
  EXO_PREVIEW_FILE_NAME = 'exo-preview-file-name'
}

export enum LOGIN_ACTIONS {
  SUBMIT = 'submit',
  SET_NEW = 'set-new',
  SET_NEW_CONFIRM = 'set-new-confirm'
}

export enum PREVIEW_DISCONNECT_TYPE {
  BEFORE_UNLOAD_EVENT = 'disconnect/before_unload_event',
  IDLE_TIMEOUT = 'disconnect/idle_timeout',
  USER_PRESSED_EXIT_PREVIEW = 'disconnect/user_pressed_exit_preview'
}

export enum VALIDATION_ACTIONS {
  INVALID_INPUT = 'invalid_input'
}

export enum RESULT_MODAL_TYPE {
  WARNING = 1,
  INFO = 2,
  ERROR = 3,
  SUCCESS = 4,
  ACCESS_DENIED = 5,
  FAILED = 6
}

export enum SHARING_RESULT_MODAL_TYPE {
  API_FAILED = 'api-failed',
  SUCCESS = 'success',
  SUCCESS_WITH_COPY_LINK = 'success-with-copy-link'
}

export enum STATUS_CODES {
  // STATUSES >= 1001
  NEW_PASSWORD_AUTH_CHALLENGE = 1001,
  AUTHENTICATION_TOKEN_MISSING = 1002,

  // STATUSES >= 2001
  MISSING_REQUEST_BODY_PARAMS = 2001,
  MISSING_SHARED_EMAILS = 2002,
  MISSING_VERIFY_EMAIL = 2003,
  INVALID_REQUEST_BODY_PARAMS = 2004,
  MISSING_REQUEST_QUERY_PARAMS = 2005,

  // STATUSES >= 3001
  SUCCESSFUL_CHALLENGE_AUTH_RESPONSE = 3001,
  SUCCESSFUL_DELETION = 3002,
  SUCCESSFUL_SHARED_EMAILS_SENT = 3003,
  SUCCESSFUL_RECIPIENT_VERIFICATION = 3004,
  SUCCESSFUL_CREATOR_ACCOUNT_VERIFICATION = 3005,
  SUCCESSFUL_NO_INSERTED_ONLY_EXISTING_EMAILS = 3006,
  SUCCESSFUL_GET_AUTHORIZED_VIDEO = 3007,
  OTP_ACCEPTED = 3008,
  SUCCESSFUL_OTP_CREATED = 3009,
  TERMS_SAVED = 3010,
  FILE_INSERT_SUCCESSFUL = 3011,
  SUCCESSFUL_USERS_ACCESS_LIST = 3012,

  // ERRORS
  SERVER_ERROR = 9001,
  INVALID_EMAIL = 9002,
  NO_RECIPIENT_FOUND = 9003,
  GET_VIDEO_UNAUTHORIZED = 9004,
  OTP_INVALID = 9005,
  OTP_EXPIRED = 9006,
  NO_USERS_FOUND = 9007
}
