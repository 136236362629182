import React, { useCallback, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'

import cx from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleInfo, faExclamationTriangle, faClose } from '@fortawesome/free-solid-svg-icons'

import { InfoIcon, AccessDeniedIcon, APIFailedIcon, SuccessIcon, CloseIcon } from '~elements'
import { disableBodyScroll } from '~utils'
import { RESULT_MODAL_TYPE } from '~enums'
import { ConfirmationModalProps } from '~interfaces'

import styles from './index.module.scss'

export const ResultModal = ({
  isOpen,
  handleModalClose,
  children,
  closeOnOutsideClick = true,
  type = RESULT_MODAL_TYPE.WARNING,
  closeBtnText = 'Close',
  actionButtonText = 'Action',
  actionButtonCallback = () => null,
  showActionButtons = true,
  showCloseOutButton = true
}: ConfirmationModalProps) => {
  const [isBrowser, setIsBrowser] = useState(false)

  useEffect(() => {
    setIsBrowser(true)
  }, [])

  useEffect(() => {
    disableBodyScroll(isOpen)
  }, [isOpen])

  const handleKeyEvent = useCallback((event: KeyboardEvent) => {
    const { key } = event
    if (key === 'Escape' && handleModalClose) {
      handleModalClose()
    }
  }, [])

  useEffect(() => {
    window.addEventListener('keydown', handleKeyEvent)
    return () => window.removeEventListener('keydown', handleKeyEvent)
  }, [handleKeyEvent])

  if (!type) {
    return <></>
  }

  const onModalClick = (e: React.MouseEvent<HTMLElement>) => {
    if (e.target === e.currentTarget && closeOnOutsideClick) {
      handleModalClose()
    }
  }

  const classNames = cx([styles['modal-content'], ...(type ? [styles[`modal-content--${type}`]] : [])])

  const handleCloseButtonClick = (e: React.MouseEvent<Element, MouseEvent>) => {
    handleModalClose()
  }

  const handleActionButtonClick = (e: React.MouseEvent<Element, MouseEvent>) => {
    actionButtonCallback()

    setTimeout(() => {
      handleModalClose()
    }, 100)
  }

  const getInfoIcon = () => {
    switch (type) {
      case RESULT_MODAL_TYPE.WARNING:
        return <InfoIcon />

      case RESULT_MODAL_TYPE.INFO:
        return <FontAwesomeIcon icon={faCircleInfo} color="#7f56d9" />

      case RESULT_MODAL_TYPE.ERROR:
        return <FontAwesomeIcon icon={faExclamationTriangle} color="#D92D20" />

      case RESULT_MODAL_TYPE.ACCESS_DENIED:
        return <AccessDeniedIcon />

      case RESULT_MODAL_TYPE.FAILED:
        return <APIFailedIcon />

      case RESULT_MODAL_TYPE.SUCCESS:
        return <SuccessIcon />

      default:
        return <></>
    }
  }

  const modalContent = isOpen ? (
    <div className={styles.modal}>
      <div className={styles['modal-dialog']} role="document" onClick={onModalClick}>
        <div className={classNames}>
          {showCloseOutButton && (
            <button className={styles['icon-close-container']} onClick={handleModalClose} data-testid="modal-close-icon-btn">
              {type === RESULT_MODAL_TYPE.FAILED || type === RESULT_MODAL_TYPE.SUCCESS ? (
                <CloseIcon />
              ) : (
                <FontAwesomeIcon icon={faClose} color="#fff" size="sm" focusable />
              )}
            </button>
          )}
          {getInfoIcon()}
          {children}
          {showActionButtons && (
            <div className={styles['cta-panel']}>
              <button data-type="secondary" onClick={handleCloseButtonClick}>
                {closeBtnText}
              </button>
              {type === RESULT_MODAL_TYPE.WARNING && (
                <button data-type="primary" onClick={handleActionButtonClick}>
                  {actionButtonText}
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  ) : null

  if (isBrowser) {
    //@ts-ignore
    return ReactDOM.createPortal(modalContent, document.getElementById('root-modal'))
  } else {
    return null
  }
}
