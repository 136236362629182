import React from 'react'
import styles from './index.module.scss'
import { VideoTitle } from '~elements'
import { Heading } from '@msgtechnology/sphere'
import { ModalHeaderProps } from '~interfaces'

export const RecipientsModalHeader = ({ fileName }: ModalHeaderProps) => {
  return (
    <div className={styles['modal-header']}>
      {/* The Heading component from @msgtechnology/sphere allows a React component to be passed as a child */}
      <Heading level={4}>
        {`Share "`}
        <VideoTitle title={fileName} />
        {`"`}
      </Heading>
    </div>
  )
}
