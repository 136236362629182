import { CopyAndShareButton, Heading, Modal, Paragraph, ResultModal } from '~elements'
import { RecipientsModalHeader } from './RecipientsModalHeader'
import { RecipientsModalBody } from './RecipientsModalBody'
import styles from './index.module.scss'
import resultModalStyles from 'elements/ResultModal/index.module.scss'
import { RESULT_MODAL_TYPE, SHARING_RESULT_MODAL_TYPE, STATUS_CODES } from '~enums'
import { useEffect, useState } from 'react'
import { RecipientsModalProps } from '~interfaces'
import { GetUserAccessList } from '~services'

export const ManageRecipientsModal = ({ fileName, videoId, isOpen, handleModalClose, shareUrl }: RecipientsModalProps) => {
  const [resultModalType, setResultModalType] = useState<SHARING_RESULT_MODAL_TYPE | null>(null)
  const [numRecipients, setNumRecipients] = useState<number | null>(null)
  const [expDate, setExpDate] = useState<string | null>(null)
  const [succeeded, setSucceeded] = useState<boolean>(false)

  const getResultModalContent = (modalType: string) => {
    switch (modalType) {
      case SHARING_RESULT_MODAL_TYPE.API_FAILED:
        return (
          <>
            <Heading level={4} className={styles['heading']}>
              Sharing Error
            </Heading>
            <Paragraph
              fontSize="md"
              justify={'center'}
              text={{ html: 'We apologize, but the sharing function is not working. Please try again later.' }}
            />
          </>
        )
      case SHARING_RESULT_MODAL_TYPE.SUCCESS:
        return (
          <>
            <Heading level={4} className={styles['heading']}>
              {`Success!\nInvitations sent to ${numRecipients} recipient${numRecipients && numRecipients > 1 ? 's' : ''}.`}
            </Heading>
            <Paragraph
              fontSize="md"
              justify={'center'}
              text={{
                html: `Access will automatically expire for these users on ${expDate}.`
              }}
            />
          </>
        )
      case SHARING_RESULT_MODAL_TYPE.SUCCESS_WITH_COPY_LINK:
        return (
          <>
            <Heading level={4} className={styles['heading']}>
              {`Success!\nShared with ${numRecipients} recipient${numRecipients && numRecipients > 1 ? 's' : ''}.`}
            </Heading>
            <Paragraph fontSize="md" justify={'center'} text={{ html: `Notifications were not sent. Please share the link directly.` }} />
            <CopyAndShareButton
              displayText={'Copy link'}
              shareTitle={'Web Preview'}
              tooltipText={'Link Copied!'}
              url={shareUrl}
              styles={resultModalStyles}
              useNavigatorShare={false}
              iconColor={'#20FFFF'}
            />
            <Paragraph
              fontSize="md"
              justify={'center'}
              text={{
                html: `Access will automatically expire for these users on ${expDate}.`
              }}
            />
          </>
        )
      default:
        return <></>
    }
  }

  const handleShareRes = (resultModalType: SHARING_RESULT_MODAL_TYPE, numRecipients?: number, expirationDate?: string) => {
    setResultModalType(resultModalType)
    if (numRecipients && expirationDate) {
      setNumRecipients(numRecipients)
      setExpDate(expirationDate)
      setSucceeded(true)
    }
  }

  const handleCloseResultModal = () => {
    setResultModalType(null)
    if (succeeded) {
      handleModalClose()
    }
  }

  return (
    <>
      <Modal isOpen={isOpen} isRecipientsModal={true} contentWidth={'narrow'} handleModalClose={handleModalClose}>
        <div className={styles['modal-content-container']}>
          <RecipientsModalHeader fileName={fileName} />
          <RecipientsModalBody fileName={fileName} videoId={videoId} shareUrl={shareUrl} handleShareResponse={handleShareRes} />
        </div>
      </Modal>
      <ResultModal
        type={resultModalType === SHARING_RESULT_MODAL_TYPE.API_FAILED ? RESULT_MODAL_TYPE.FAILED : RESULT_MODAL_TYPE.SUCCESS}
        closeBtnText="Close"
        isOpen={!!resultModalType}
        handleModalClose={handleCloseResultModal}
      >
        {resultModalType && <div className={'result-modal-content-wrapper'}>{getResultModalContent(resultModalType)}</div>}
      </ResultModal>
    </>
  )
}
