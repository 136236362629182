import cx from 'classnames'
import { MiniCloseOutIcon } from '~elements'
import styles from '../index.module.scss'
import { IEmailItem } from '~interfaces'

interface IEmailList {
  emails: IEmailItem[]
  removeEmail: (idx: number) => void
  selectedEmail?: IEmailItem
  setSelectedEmail: React.Dispatch<React.SetStateAction<IEmailItem | undefined>>
  selectedEmailIdx?: number
  setSelectedEmailIdx: React.Dispatch<React.SetStateAction<number | undefined>>
}

export const EmailList = ({ emails, removeEmail, selectedEmail, setSelectedEmail, selectedEmailIdx, setSelectedEmailIdx }: IEmailList) => {
  const handleDelete = (event: React.MouseEvent) => {
    const { currentTarget } = event
    const idx = Number((currentTarget as HTMLButtonElement).value)
    removeEmail(idx)
  }

  const toggleSelectedEmailItem = (emailItem: IEmailItem, idx: number) => {
    if (emailItem.email === selectedEmail?.email && idx === selectedEmailIdx) {
      setSelectedEmail(undefined)
      setSelectedEmailIdx(undefined)
    } else {
      setSelectedEmail(emailItem)
      setSelectedEmailIdx(idx)
    }
  }

  const displayEmail = (emailItem: IEmailItem, idx: number) => {
    return (
      <div
        key={idx}
        className={cx(
          styles[emailItem.isValid ? 'valid-email' : 'invalid-email'],
          styles[selectedEmail?.email === emailItem.email && selectedEmailIdx === idx ? 'selected-email' : '']
        )}
        {...(!emailItem.isValid && { tooltip: 'Invalid email address' })}
      >
        <div className={styles['email']} onClick={() => toggleSelectedEmailItem(emailItem, idx)}>
          {emailItem.email}
        </div>
        <button className={styles['delete-button']} value={idx} onClick={handleDelete}>
          <MiniCloseOutIcon isRed={!emailItem.isValid ? true : false} />
        </button>
      </div>
    )
  }

  return <>{emails.length ? emails.map((emailItem, idx) => displayEmail(emailItem, idx)) : null}</>
}
