export const APIFailedIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54" fill="none">
      <rect x="0.5" y="0.5" width="53" height="53" rx="26.5" stroke="#6D0000" />
      <mask id="mask0_7482_13894" maskUnits="userSpaceOnUse" x="15" y="14" width="25" height="25">
        <rect x="15.1227" y="14.9961" width="24" height="24" fill="#FF3D22" />
      </mask>
      <g mask="url(#mask0_7482_13894)">
        <path
          d="M27.1227 31.4576C27.297 31.4576 27.4432 31.3986 27.5612 31.2806C27.6792 31.1628 27.7382 31.0167 27.7382 30.8423C27.7382 30.6678 27.6792 30.5217 27.5612 30.4038C27.4432 30.2858 27.297 30.2268 27.1227 30.2268C26.9483 30.2268 26.8022 30.2858 26.6842 30.4038C26.5662 30.5217 26.5072 30.6678 26.5072 30.8423C26.5072 31.0167 26.5662 31.1628 26.6842 31.2806C26.8022 31.3986 26.9483 31.4576 27.1227 31.4576ZM26.6227 28.1498H27.6227V22.1498H26.6227V28.1498ZM27.1259 35.9961C25.8814 35.9961 24.7113 35.7599 23.6157 35.2876C22.5202 34.8153 21.5672 34.1743 20.7567 33.3646C19.9462 32.5549 19.3046 31.6028 18.8319 30.5081C18.3591 29.4136 18.1227 28.244 18.1227 26.9993C18.1227 25.7548 18.3588 24.5848 18.8312 23.4891C19.3035 22.3936 19.9445 21.4406 20.7542 20.6301C21.5638 19.8196 22.516 19.178 23.6107 18.7053C24.7052 18.2325 25.8748 17.9961 27.1194 17.9961C28.3639 17.9961 29.534 18.2323 30.6297 18.7046C31.7252 19.1769 32.6782 19.8179 33.4887 20.6276C34.2992 21.4373 34.9408 22.3894 35.4134 23.4841C35.8863 24.5786 36.1227 25.7482 36.1227 26.9928C36.1227 28.2373 35.8865 29.4074 35.4142 30.5031C34.9418 31.5986 34.3008 32.5516 33.4912 33.3621C32.6815 34.1726 31.7293 34.8142 30.6347 35.2868C29.5402 35.7597 28.3706 35.9961 27.1259 35.9961ZM27.1227 34.9961C29.356 34.9961 31.2477 34.2211 32.7977 32.6711C34.3477 31.1211 35.1227 29.2294 35.1227 26.9961C35.1227 24.7628 34.3477 22.8711 32.7977 21.3211C31.2477 19.7711 29.356 18.9961 27.1227 18.9961C24.8893 18.9961 22.9977 19.7711 21.4477 21.3211C19.8977 22.8711 19.1227 24.7628 19.1227 26.9961C19.1227 29.2294 19.8977 31.1211 21.4477 32.6711C22.9977 34.2211 24.8893 34.9961 27.1227 34.9961Z"
          fill="#FF3D22"
        />
      </g>
    </svg>
  )
}
