import { IGetUserAccessListRequestBody } from '~interfaces'
import { getIdToken } from '~utils'

export const GetUserAccessList = async ({ videoId }: IGetUserAccessListRequestBody) => {
  try {
    const headers = new Headers()
    const id = getIdToken()
    headers.append('Authorization', `Bearer ${id}`)

    const result = await fetch(`${process.env.NEXT_PUBLIC_API_ENDPOINT}/user-access-list?id=${videoId}`, {
      method: 'GET',
      headers
    })
    const response = await result.json()
    return response
  } catch (e) {
    if (e instanceof Error) {
      throw new Error(e.message)
    } else {
      throw new Error('Unable to get user access list.')
    }
  }
}
