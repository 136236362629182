import { IVPCategory, IVPThumbnailDetails } from '~interfaces'
import { LOCAL_STORAGE } from '~enums'
import { categorizeVantagePointData, getCookie } from '~utils'

interface IVantagePointData {
  data: {
    categorizedData: Array<IVPCategory>
    rawData: Array<IVPThumbnailDetails>
  }
}
export const GetVantagePoints = async ({ isRecipient, videoId }: { isRecipient?: boolean; videoId?: string }): Promise<IVantagePointData> => {
  try {
    const headers = new Headers()
    const userSessionData = localStorage.getItem(LOCAL_STORAGE.USER_KEY)

    if (!userSessionData && !isRecipient) {
      throw new Error('Session data is missing.')
    }

    if (userSessionData) {
      const { id } = JSON.parse(userSessionData)
      headers.append('Authorization', `Bearer ${id}`)
    } else if (isRecipient) {
      const recipientCookie = getCookie('epu')
      headers.append('Authorization', `Bearer ${recipientCookie}`)
    }

    const result = await fetch(`${process.env.NEXT_PUBLIC_API_ENDPOINT}/vantage-points${isRecipient ? `?id=${videoId}` : ''}`, {
      method: 'GET',
      headers
    })
    const results: { data: Array<IVPThumbnailDetails> } = { data: await result.json() }

    const categorizedData = categorizeVantagePointData(results.data)
    return { data: { categorizedData, rawData: results.data } }
  } catch (e) {
    throw new Error('Unable to get vantage point data.')
  }
}
