import React from 'react'
import styles from '../index.module.scss'
import { UserIcon } from '~elements'

export const UserAccessList = ({ accessList }: { accessList: string[] }) => {
  return (
    <div className={styles['who-can-access-container']}>
      <h2 className={styles['who-can-access-title']}>Who can access</h2>
      <div className={styles['recipient-list']}>
        {accessList.map((recipient, index) => (
          <div key={index} className={styles['recipient-item']}>
            <UserIcon />
            <span className={styles['recipient-email']}>{recipient}</span>
          </div>
        ))}
      </div>
    </div>
  )
}
