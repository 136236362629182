export interface IGetVideoRequestBody {
  videoId: string
}

export const GetVideo = async ({ videoId }: IGetVideoRequestBody) => {
  try {
    const result = await fetch(`${process.env.NEXT_PUBLIC_API_ENDPOINT}/video?id=${videoId}`, {
      method: 'GET',
      credentials: 'include'
    })
    const data = await result.json()

    return { data }
  } catch (e) {
    throw new Error('Unable to get video for recipient.')
  }
}
