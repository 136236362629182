import { IVPCategory, IVPThumbnailDetails } from '~interfaces'

export const categorizeVantagePointData = (data: IVPThumbnailDetails[]): Array<IVPCategory> => {
  const categorizedData: Array<IVPCategory> = []
  let currCategoryId = ''
  let currCategoryIdx = -1
  let currGroupId = ''
  let currGroupIdx = -1

  for (let i = 0; i < data.length; i++) {
    const curr: IVPThumbnailDetails = data[i]
    const thumbnailUrl = `https://${process.env.NEXT_PUBLIC_EXO_CLOUDFRONT_DIST}/${curr.thumbnail_image_key}`
    const currWithThumbnailUrl = { ...curr, thumbnail_url: thumbnailUrl }

    if (currCategoryId !== curr.category_id) {
      categorizedData.push({
        id: curr.category_id,
        category_name: curr.category_name,
        groups: [
          {
            id: curr.group_id,
            group_name: curr.group_name,
            group_subtitle: curr.group_subtitle ? curr.group_subtitle : undefined,
            vantage_points: [{ ...currWithThumbnailUrl }]
          }
        ]
      })
      currCategoryId = curr.category_id
      currGroupId = curr.group_id
      currCategoryIdx++
      currGroupIdx = 0
    } else if (currCategoryId === curr.category_id) {
      if (currGroupId !== curr.group_id) {
        const newGroup = {
          id: curr.group_id,
          group_name: curr.group_name,
          group_subtitle: curr.group_subtitle ? curr.group_subtitle : undefined,
          vantage_points: [{ ...currWithThumbnailUrl }]
        }
        categorizedData[currCategoryIdx].groups.push(newGroup)
        currGroupId = curr.group_id
        currGroupIdx++
      } else {
        categorizedData[currCategoryIdx].groups[currGroupIdx].vantage_points.push({ ...currWithThumbnailUrl })
      }
    }
  }
  return categorizedData
}
